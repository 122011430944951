.inspectionHeader {
    height: 56px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.inspectionHeader .inspectionId, .inspectionHeader .inspectionType {
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    padding-left: 25px;
}
.inspectionHeader .inspectionLeft {
    display: flex;
    align-items: center;
}
.inspectionHeader .inspectionLeft .inspectionType {
    padding-left: 15px;
}
.inspectionHeader .inspectionLeft .inspectionType p {
    margin: 0;
    padding: 0;
}
.inspectionHeader .inspectionLeft .inspectionType .reasons {
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    margin: 4px 0;
}
.blueBack, .orangeBackground {
    background-color: #0059a3;
}
.blueBack .timerWrapper, .orangeBackground .timerWrapper {
    background-color: #2c71ae;
    color: #fff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 12px;
    padding: 9px 0;
    min-width: 80px;
    text-align: center;
    margin-left: 20px;
}
.orangeBackground {
    background-color: #e87211;
}
.orangeBackground .timerWrapper {
    background-color: #e58738;
}
.historyWrapper p {
    margin: 0;
    font-size: 14px;
    color: #fff;
    font-weight: bold;
    position: relative;
    cursor: pointer;
    height: 34px;
    border-radius: 9px;
    border: solid 1px #fff;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 24px 0 10px;
    margin-right: 20px;
    user-select: none;
}
.historyWrapper p::after {
    content: '';
    background-image: url('../images/comArrow.svg');
    background-repeat: no-repeat;
    width: 12px;
    height: 10px;
    position: absolute;
    top: 13px;
    right: 6px;
    transform: rotate(180deg);
}
.historyWrapper .historyData {
    border: solid 1px #c6c6c6;
    border-top: none;
    background-color: #e0ecf6;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    min-width: 350px;
    max-height: 330px;
    overflow-y: scroll;
    position: absolute;
    top: 56px;
    right: 10px;
}
.historyWrapper .historyData ul {
    margin: 0;
    padding: 0;
}
.historyWrapper .historyData ul li {
    list-style: none;
    border-bottom: 1px solid #d0d0d0;
    line-height: 34px;
    min-height: 34px;
    display: flex;
    justify-content: space-between;
    padding: 0 12px;
}
.historyWrapper .historyData ul li:last-child {
    border-bottom: none;
}
.historyWrapper .historyData ul li p {
    font-size: 12px;
    font-weight: bold;
    color: #000;
    padding: 0;
}
.historyWrapper .historyData ul li span {
    font-size: 12px;
    color: #000;
    min-width: 150px;
}
.assignTimerWrapper {
    display: flex;
    align-items: center;
}
.viewReport {
    text-decoration: underline;
    font-size: 16px;
    font-weight: 700;
    color: #fff;
}
.inspectionStatusDone {
    margin-left: 20px !important;
    border-radius: 3px;
    background-color: #E19E35;
    font-size: 12px;
    color: #fff;
    padding: 5px !important;
}

.newInspectionChip {
    color: #fff !important; 
    margin-inline: 10px;
    font-size: 600;
}