.reviewWrapper {
    margin-top: 145px;
}
.reviewWrapper .carouselTextWrapper {
    display: flex;
    justify-content: space-between;
    padding: 0 20px;
    margin-top: 70px;
}
.reviewWrapper .carouselTextWrapper .carousel {
    flex: 1 1 60%;
    width: 60%;
    position: relative;
}
.additionalWrapper {
    display: flex;
    position: absolute;
    right: 12px;
    top: -14px;
    z-index: 1;
}
.additionalWrapper .rc, .additionalWrapper .additional {
    border-radius: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 28px;
    font-weight: bold;
    margin: 0;
    font-size: 16px;
}
.additionalWrapper .rc span, .additionalWrapper .additional span {
    min-width: 15px;
    min-height: 15px;
    border-radius: 100%;
    display: inline-flex;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    font-weight: normal;
}
.additionalWrapper .rc {
    background-color: #0059a3;
    color: #fff;
    min-width: 80px;
}
.additionalWrapper .rc span {
    color: #fff;
    background-color: #5ea9ea 61;
}
.additionalWrapper .additional {
    background-color: #d8d8d8;
    color: #141414;
    min-width: 130px;
    margin: 0 0 0 12px;
}
.additionalWrapper .additional span {
    color: #141414;
    background-color: #b1afaf;
}

.greyStrip {
    background-color: #e0ecf6;
    min-height: 50px;
    display: flex;
    align-items: center;
    padding: 0 16px;
    padding-left: 26px;
    font-size: 14px;
}